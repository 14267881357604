.arrow_navigation {
  @apply w-full h-full;

  // background properties
  background-image: linear-gradient(black, black),
    linear-gradient(#ff9a0a, #ff9a0a);
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 100%;

  // mask image properties
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-position: center;
  -webkit-mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}
