@import "../../../assets/styles/global.scss";

.review {
  --var-padding-y: 24px;

  @media (min-width: 1440px) {
    --var-padding-y: 40px;
  }

  .swiper-slide {
    @apply flex h-[200px] sm:h-[300px] 2xl:h-[337px] relative;
    padding: calc(var(--var-padding-y) * 1.5) 0;
    transition: padding 0.25s;

    .quote {
      @apply absolute z-10;
      @apply opacity-0;
      transition: opacity 0.25s;

      img {
        @apply w-[64px] lg:w-[92px] h-auto;
      }
    }

    .quote-left {
      top: 0px;
      left: 0px;
      transform: translate(0, -50%);
    }

    .quote-right {
      bottom: 0px;
      right: 0px;
      transform: translate(0, 50%);
    }

    .slide-content {
      @apply p-6 text-center flex flex-col justify-center gap-y-[8px];

      h3 {
        @apply text-[12px] leading-[16px] lg:text-xs 2xl:text-sm;
        @apply font-apollo  tracking-[0.06em];
      }

      .slide-text {
        @apply font-poppins font-light;
        @apply text-[12px] leading-[16px] xl:text-[14px] xl:leading-[18px];
      }

      .slide-author {
        @apply font-poppins font-semibold text-[12px] lg:text-[14px];
      }
    }
  }

  .swiper-slide-active {
    padding: 0 0;
    transition: padding 0.25s;

    .quote {
      @apply opacity-100;
      transition: opacity 0.25s;
    }

    .slide-content {
      @apply border border-brand-2;
      @apply px-[32px] py-[40px] gap-y-[8px] xl:gap-y-[24px];

      h3 {
        @apply text-base-apollo;
      }

      .slide-text {
        @apply text-xs-apollo lg:text-sm-apollo 2xl:text-base-apollo;
      }

      .slide-author {
        @apply font-poppins font-semibold text-[14px] lg:text-[16px] 2xl:text-[20px];
      }
    }
  }
}
