@import "../../../assets/styles/global.scss";

.footer {
  @apply bg-background;
  @extend .my-container;
  .contents {
    @apply grid grid-cols-12 border-t border-b border-black pt-[40px] sm:pt-[64px] lg:pt-[120px];
    .left {
      @apply col-span-full lg:col-start-2 lg:col-end-5  flex flex-col gap-y-[40px] lg:gap-y-0 lg:flex-row justify-between items-baseline;
      @apply row-start-2 row-end-3 lg:row-start-1 lg:row-end-2;
      @apply mt-[40px] lg:mt-0;
      h2 {
        @apply font-poppins  text-xs sm:text-sm 2xl:text-base font-semibold;
      }
      p {
        @apply font-poppins font-light text-xs lg:text-sm 2xl:text-base mt-[8px] sm:mt-[16px] lg:mt-[32px];
      }
    }

    .right {
      @apply row-start-1 row-end-2 lg:row-start-1 lg:row-end-2;
      @apply col-span-full lg:col-start-7 2xl:col-start-7 lg:col-end-12;

      .navs {
        @apply flex flex-col lg:flex-row justify-between items-baseline;

        .link {
          @apply first:mt-0 mt-[16px] lg:m-0 inline-block;
          @apply font-apollo text-sm-apollo 2xl:text-base-apollo tracking-[0.04em];

          .label {
            @apply relative w-full block;

            &::after {
              @apply absolute block  bottom-0 right-[-10%] border-b-2 border-flush-orange-500 min-w-[24px];
              content: "";
              width: calc(100% - 50%);
              clip-path: inset(0 0 0 100%);
              transition: clip-path 0.25s;
            }
          }

          &:hover {
            .label {
              &::after {
                clip-path: inset(0 0 0 0);
                transition: clip-path 0.25s;
              }
            }
          }
        }

        .active {
          .label {
            &::after {
              @apply border-b-black;
              clip-path: inset(0 0 0 0);
            }
          }
        }
      }

      .rate_container_desktop {
        @apply hidden lg:flex justify-end lg:mt-[56px] 2xl:mt-[68px];
      }
    }

    .bottom {
      @apply col-span-full lg:col-start-2 lg:col-end-12 mt-[40px] lg:mt-[64px] 2xl:mt-[80px] flex flex-col-reverse lg:flex-row justify-between items-start lg:items-center;

      .logo {
        @apply w-full sm:w-[391px] h-auto;
        @apply mt-[40px] lg:mt-0;
      }

      .social_network {
        @apply flex gap-x-[24px] sm:gap-x-[32px] lg:gap-x-[44px] 2xl:gap-x-[48px];

        .social_network_pinterest {
          @apply w-[33px] 2xl:w-[35px] h-[32px] 2xl:h-[35px] bg-black hover:bg-flush-orange-500;
        }

        .social_network_houzz {
          @apply w-[16px] 2xl:w-[17px] h-[28px] 2xl:h-[30px] bg-black hover:bg-flush-orange-500;
        }
      }
    }

    .partenaire_container {
      @apply col-span-full xl:col-start-1 xl:col-end-13 pt-[40px] sm:pt-[64px] lg:pt-[41px] pb-[8px] flex justify-end;
      img {
        @apply w-full h-auto md:h-[53px] object-cover object-center md:object-right;
      }
      .desktop {
        @apply hidden md:block;
      }
      .mobile {
        @apply block md:hidden;
      }
    }
  }

  .mention_legal {
    @apply grid grid-cols-12 py-[24px] lg:py-[32px];
    p {
      @apply font-poppins text-[10px] leading-[11.72px] sm:text-xxs col-span-full lg:col-start-2 lg:col-end-12;
      a {
        @apply hover:underline;
      }
    }
  }
}
