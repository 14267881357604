@import "../../../assets/styles/global.scss";

.contact {
  @extend .my-container;
  @extend .main-mt;
  @apply grid grid-cols-6 md:grid-cols-12 pb-[80px] md:pb-[120px];

  .header {
    @apply col-span-full md:col-start-1 xl:col-start-2 md:col-end-8 2xl:col-end-13 row-start-1 row-end-2;
    .bread_crumb {
      @apply hidden lg:flex col-span-full;
    }

    h1 {
      @extend .my-h1;
      @apply mt-[4px] col-span-full;
    }
  }

  .contents {
    @apply col-span-full grid grid-cols-12;
  }

  .form_container_in_contact_page {
    @apply col-span-full md:col-start-1 xl:col-start-2 md:col-end-8 2xl:col-end-7;
  }

  .infos_container {
    @apply col-span-full md:col-start-9 md:col-end-13;

    @media (max-width: 786px) {
      @apply row-start-1 row-end-2 mt-[32px];
    }

    ._block {
      @apply 2xl:grid grid-cols-4 2xl:grid-cols-5;
      @apply w-full bg-white h-auto ml-[-30px] md:ml-0 px-[30px] md:pl-[40px] lg:pl-[64px] 2xl:pl-0 lg:pr-[60px];
      @apply mt-0 md:mt-[32px] py-[32px] lg:py-[64px] 2xl:py-[80px];

      width: calc(100% + 60px);

      .container {
        @apply col-start-2 col-end-5;

        h2 {
          @extend .my-h2;
          @apply relative inline-block;
          span {
            @apply absolute top-[100%]  block w-[22px] right-[-4px] sm:right-[-12px] xl:right-[-16px] sm:w-[34px] 2xl:w-[64px] border-b-2 border-flush-orange-500;
          }
        }
        p {
          @extend .paragraph;
          @apply mt-[16px] md:mt-[24px] xl:mt-[32px];
        }
        .tel {
          @apply font-bold;
        }
        
      }
    }
  }
}

.form_container {
  .text {
    @extend .paragraph;
    @apply max-w-prose;
  }

  .tow_input_in_line {
    @apply flex flex-wrap md:flex-nowrap md:gap-x-[16px];

    & > div {
      @apply w-full md:w-1/2;
    }
  }

  .spacing_between_inputs {
    @apply mt-[24px] lg:mt-[32px];
  }

  .form {
    @apply mt-[24px] lg:mt-[32px] 2xl:mt-[46px];

    .nom_prenom {
      @extend .tow_input_in_line;

      .prenom {
        @apply mt-[24px] md:mt-0;
      }
    }

    .textarea_container {
      @apply w-full flex flex-col;
      @extend .spacing_between_inputs;

      .textarea {
        @apply w-full focus:outline-none bg-transparent;
        @apply border border-zinc-900;
        @apply h-[128px] lg:h-[142px];
        @extend .input_padding;
        @extend .input_styles_text;
        font-weight: 400 !important;

        &::placeholder {
          @extend .input_styles_text;
          @apply text-[#999999];
        }
      }
    }

    .from {
      @extend .spacing_between_inputs;
    }

    .policy_text {
      @apply mt-[24px] md:mt-[40px] lg:mt-[48px];

      p {
        @apply font-poppins text-[12px] tracking-[0.48px] uppercase;

        a {
          @apply inline-block underline md:no-underline hover:underline;
        }
      }
    }

    .submit_btn_container {
      @apply mt-[24px] md:mt-[8px] w-full;
      button {
        @apply px-[16px] py-[10px] sm:px-[24px] sm:py-[12px];
      }
    }
  }
}

.tel_input {
  @apply mt-[24px] lg:mt-[32px];
}

.input_padding {
  @apply px-[12px] py-[10px] lg:p-[20px] 2xl:py-[18px] 2xl:px-[16px];
}

.height_input {
  @apply px-[12px] lg:px-[16px] h-[37px] lg:h-[60px];
}

.input_styles_text {
  @apply font-poppins font-semibold text-[12px] lg:text-[16px];
}

.input_container {
  @apply w-full flex flex-col;

  input {
    @extend .input_styles_text;
    @apply bg-transparent focus:outline-none font-normal;
    @apply border border-zinc-900;
    @extend .height_input;

    &::placeholder {
      @extend .input_styles_text;
      @apply text-[#999999];
    }
  }
}

.error_input {
  @apply block font-poppins text-[10px] lg:text-[12px] text-red-500 mt-[2px];
}

.confirmation_message {
  @apply px-4 py-4 rounded-sm;
  @apply mt-[16px] md:mt-[24px] lg:mt-[32px] 2xl:mt-[46px] text-white;

  @extend .paragraph;
}

.fails {
  background-color: rgba(255, 0, 0, 0.205);
}

.success {
  background-color: rgba(0, 255, 85, 0.219);
}
