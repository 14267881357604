.navbar {
  @apply flex bg-background relative z-[1000] justify-between lg:block w-full lg:pt-[64px] pt-[40px] pb-[44px] px-[30px] lg:px-0 lg:py-0;
  @apply border-b border-black lg:border-none;

  .logo {
    @apply lg:w-full lg:flex lg:justify-center;
    a {
      @apply inline-block;
    }
    .logo_img {
      @apply w-[217px] lg:w-[358px] 2xl:w-[391px] h-auto;
    }
  }

  .navs {
    @apply w-full hidden lg:flex flex-row justify-center items-center lg:mt-[21px] 2xl:mt-[48px] gap-x-16;
    @apply left-0 lg:top-0 px-[30px] lg:px-0 lg:py-0;
    .nav {
      @apply inline-block;
      .link {
        @apply block font-apollo text-sm-apollo 2xl:text-base-apollo tracking-[0.04em] relative;
        &::after {
          content: "";
          @apply border-b-2 border-flush-orange-500 min-w-[24px] w-[50%] absolute top-[102%] hidden lg:block opacity-0;
          clip-path: inset(0 0 0 100%);
          transition: clip-path 0.25s, opacity 0.25s;
          left: calc(100% - 40%);
        }
      }
      .active {
        &::after {
          @apply border-black opacity-100;
          clip-path: inset(0 0 0 0) !important;
          left: calc(100% - 40%);
        }
      }
      &:hover {
        .link {
          &::after {
            clip-path: inset(0 0 0 0);
            transition: clip-path 0.25s, opacity 0.25s;
            @apply opacity-100;
          }
        }
      }
    }
    .btn_contact {
      @apply px-[24px] py-[12px] inline-block;
    }
  }

  .navs_mobile {
    @apply hidden;
  }

  .menu_btn {
    @apply block lg:hidden;
    @apply w-[27px] h-[27px];
  }
}

.menuMobile {
  @apply lg:hidden w-full z-[999] fixed top-0 left-0 bg-background flex flex-col;
  height: calc(100vh - 112px);
  transform: translateY(-100%);
  transition: transform ease-out 0.25s;
  .navs_container {
    @apply flex flex-col justify-between grow py-[68px] items-center;
    .link_nav {
      @apply font-apollo text-base-apollo tracking-[0.04em] inline-block;
    }
    .active_menu_mobile {
      @apply relative inline-block;
      span {
        @apply block w-full;
        &::after {
          @apply absolute bottom-0 right-[-8px] w-[60%] border-b-2 border-flush-orange-500;
          content: " ";
          clear: both;
        }
      }
    }
    @media (max-height: 781px) {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      justify-content: space-around;
    }
  }
  .separator {
    @apply mx-[56px] border-b-2 border-brand-2;
  }
  .contact_container {
    @apply pt-[62px] pb-[108px];
    @apply font-apollo text-base-apollo tracking-[0.04em] inline-block text-center;
    p {
      @apply mt-[16px];
    }
    @media (max-height: 781px) {
      padding-top: 40px !important;
      padding-bottom: 40px !important;
    }
  }
}

.openMenuMobile {
  transform: translateY(0%) !important;
  top: 112px !important;
  transition: transform ease-in 0.25s;
}

.headerWhenOpenMenu {
  position: fixed !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
}
