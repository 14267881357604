.bread_crumb {
  @apply w-full flex items-baseline font-poppins text-xxxs uppercase tracking-[0.04em];
  .active {
    @apply text-xxxs underline;
  }
  a {
    &:hover {
      @extend .active;
    }
  }
  span {
    @apply mx-[2px];
  }
}
