@import "../../../assets/styles/global.scss";

.entretien_conception {
  @extend .main-mt;
  .bread_crumb_container {
    @extend .my-container;
    @apply grid-cols-12 hidden lg:grid;
    .bread_crumb {
      @apply col-span-full xl:col-start-2 xl:col-end-12;
    }
  }
  .title_and_paragraph {
    @extend .my-container;
    @apply grid-cols-12 grid;

    h1 {
      @extend .my-h1;
      @apply col-span-full xl:col-start-2 xl:col-end-12 mt-[4px];
    }

    .text_container {
      @apply col-span-full xl:col-start-2  xl:col-end-12;
      @apply mt-[16px] lg:mt-[32px];

      p {
        @extend .paragraph;
      }
    }
  }

  .steps_container_block {
    @apply mt-[36px] sm:mt-[32px] lg:mt-[40px] 2xl:mt-[60px];
  }

  .cards_container {
    @apply w-full mt-[40px] sm:mt-[64px] lg:mt-[80px] 2xl:mt-[120px];

    .spacing_content_card {
      @apply mt-[8px] sm:mt-[16px] 2xl:mt-[32px];
    }
  }
}
