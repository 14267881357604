@import "../../../assets/styles/global.scss";

.dimensions {
  @media (min-width: 340px) {
    @apply h-[232px] sm:h-[244px] 2xl:h-[336px];
  }
  @apply w-full h-[190px] sm:h-[244px] 2xl:h-[336px] relative;

  .img_container {
    @apply absolute z-[1] overflow-hidden;

    img {
      @apply w-full h-full object-cover object-center;
      transform: scale(1);
      transition: transform 0.25s;
    }

    &:hover {
      img {
        transform: scale(1.05);
        transition: transform 0.25s;
      }
    }

    width: calc(100% - 18.31px);
    height: calc(100% - 18.31px);
    @media (min-width: 1024px) {
      width: calc(100% - 20px);
      height: calc(100% - 20px);
    }
    @media (min-width: 1280px) {
      width: calc(100% - 24px);
      height: calc(100% - 24px);
    }
    @media (min-width: 1536px) {
      width: calc(100% - 32px);
      height: calc(100% - 32px);
    }
  }

  .bg_orange {
    @apply absolute z-0;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    @apply bg-brand-2;
    @media (min-width: 1024px) {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
    }
    @media (min-width: 1280px) {
      width: calc(100% - 42px);
      height: calc(100% - 42px);
    }
    @media (min-width: 1536px) {
      width: calc(100% - 58px);
      height: calc(100% - 58px);
    }
  }
}

.first_image {
  .image_container {
    @extend .dimensions;
    .img_container {
      bottom: 0px;
      right: 0px;
    }
    .bg_orange {
      top: 0px;
      left: 0px;
    }
  }
}

.second_image {
  .image_container {
    @extend .dimensions;
    .img_container {
      top: 0px;
      left: 0px;
    }
    .bg_orange {
      bottom: 0px;
      right: 0px;
    }
  }
}

.third_image {
  .image_container {
    @extend .dimensions;
    .img_container {
      left: 0px;
      bottom: 0px;
    }
    .bg_orange {
      top: 0px;
      right: 0px;
    }
  }
}

.fourth_image {
  .image_container {
    @extend .dimensions;
    .img_container {
      top: 0px;
      right: 0px;
    }
    .bg_orange {
      left: 0px;
      bottom: 0px;
    }
  }
}

.first_image_left {
  @extend .second_image;
}

.second_image_left {
  @extend .fourth_image;
}

.third_image_left {
  @extend .second_image;
}

.card_container {
  @extend .my-container;
  .card {
    @apply mt-[40px] sm:mt-[64px] lg:mt-[80px] 2xl:mt-[120px] cursor-pointer block;
    &:hover {
      .img_container {
        img {
          transform: scale(1.05) !important;
          transition: transform 0.25s !important;
        }
      }
    }
  }

  .desktop {
    @apply hidden sm:grid grid-cols-12;

    .left {
      @apply col-start-1 xl:col-start-2 col-end-6;
      .card {
        @apply first:mt-0;
      }
      .card:nth-child(4n + 1) {
        @extend .first_image;
      }
      .card:nth-child(4n + 2) {
        @extend .second_image;
      }
      .card:nth-child(4n + 3) {
        @extend .third_image;
      }
      .card:nth-child(4n + 4) {
        @extend .fourth_image;
      }
    }

    .right {
      @apply col-start-8 col-end-13 xl:col-end-12;
      .card {
        @apply first:mt-[244px] 2xl:first:mt-[333px];
      }
      .card:nth-child(3n + 1) {
        @extend .first_image_left;
      }
      .card:nth-child(3n + 2) {
        @extend .second_image_left;
      }
      .card:nth-child(3n + 3) {
        @extend .third_image_left;
      }
    }
  }

  .mobile {
    @apply block w-full sm:hidden;
    .card:nth-child(4n + 1) {
      @extend .first_image;
    }
    .card:nth-child(4n + 2) {
      @extend .second_image;
    }
    .card:nth-child(4n + 3) {
      @extend .third_image;
    }
    .card:nth-child(4n + 4) {
      @extend .fourth_image;
    }
  }
}
