.hover_animation {
  border-top: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-bottom: 1px solid #ff9a0a !important;
  .label_container {
    .black {
      top: -100% !important;
    }
    .orange {
      top: 0px !important;
    }
  }
}

.button {
  @apply inline-block border-t border-b border-l border-r border-black py-[10px] px-[16px] lg:py-[12px] lg:px-[24px];
  @apply font-apollo text-[12px] lg:text-[16px] tracking-[0.04em];
  .label_container {
    @apply relative block overflow-hidden;
    .placeholder {
      @apply block opacity-0;
    }
    .black {
      @apply block absolute top-0 left-0;
      transition: ease top 0.3s;
    }
    .orange {
      @apply block absolute top-[100%] left-0 text-flush-orange-500;
      transition: ease top 0.3s;
    }
  }

  &:hover {
    @extend .hover_animation;
  }
}
