@import "../../../assets/styles/global.scss";

.steps_container {
  @extend .my-container;
  @apply grid-cols-12 grid;

  .number_container {
    @apply col-span-full xl:col-start-2 xl:col-end-12;

    .step_num {
      @apply inline-block mr-[32px] xl:mr-[26px];
      @extend .my-h1;
    }
  }

  .texts {
    @apply col-span-full xl:col-start-2 xl:col-end-12 grid grid-cols-10 mt-[8px] md:mt-[24px] lg:mt-[40px];

    .left {
      @apply col-span-full md:col-span-7 grid grid-cols-6 md:grid-cols-12 md:gap-x-[16px];

      h3 {
        @extend .paragraph;
        @apply font-semibold col-span-full md:col-start-1 md:col-end-6;
      }

      .text_container {
        @apply col-span-full md:col-start-6 md:col-end-13 mt-[8px] md:mt-0;

        p {
          @extend .paragraph;

          span {
            @apply font-semibold;
          }
        }
      }
    }

    .right {
      @apply col-span-full md:col-span-4 grid grid-cols-6 md:grid-cols-8 mt-[8px] md:mt-0;

      .img_step {
        @apply col-span-full md:col-start-2 md:col-span-7 object-cover object-center;
        position: relative !important;
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}
