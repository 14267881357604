@import "../../../assets/styles/global.scss";

.contents_local {
  @apply mt-[16px] sm:mt-[40px] lg:mt-[80px] 2xl:mt-[120px] col-span-full xl:col-start-2 xl:col-end-12 grid grid-cols-12 xl:grid-cols-10;
}

.h2_local {
  @apply col-span-12 xl:col-start-2 xl:col-end-12;
}

.paragraph_local {
  @apply font-light font-poppins text-xs lg:text-sm 2xl:text-base;
}

.h3_local {
  @extend .my-h3;
}

.homepage {
  @extend .my-container;
  .hero_container {
    @apply w-full relative flex flex-col;

    .images_container {
      @apply relative;

      .hero_image_desktop {
        @apply w-full h-full hidden md:block relative object-cover;
        object-position: center bottom;
      }

      .hero_image_mobile {
        @apply block md:hidden w-full h-auto object-cover object-bottom;
      }

      .paysagiste_desktop {
        @apply hidden md:block absolute z-[1] left-[-3px] top-[101%];
        transform: translate(0%, -100%);
        @apply w-[100%] h-auto;
      }

      .gradient {
        @apply absolute top-0 left-0 z-[1] h-[20%] sm:h-[35%] w-full;
        background: linear-gradient(
          180deg,
          rgba(233, 230, 223, 1) 44%,
          rgba(0, 0, 0, 0) 92%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    .arrow {
      @apply sm:h-[70px] lg:h-[80px] 2xl:h-[100px];
      @apply hidden sm:flex justify-center pb-[8px] pt-[12px] lg:pt-[16px];

      .gif_arrow {
        @apply h-full w-auto;
      }
    }
  }

  h2 {
    @extend .h2_local;
  }

  .first_block {
    @apply mt-[40px] sm:mt-[80px] lg:mt-[216px] 2xl:pt-[272px];
    h1 {
      @extend .my-h1;
      @apply sm:text-center;
    }
    .contents {
      @apply grid grid-cols-6 sm:grid-cols-12 mt-[32px] sm:mt-[64px] lg:mt-[120px] sm:gap-x-[56px] lg:gap-x-0;
      div:first-child {
        @apply col-span-6  lg:col-start-1 xl:col-start-2 lg:col-end-6;
      }
      div:last-child {
        @apply col-span-6  lg:col-start-8  lg:col-end-13 xl:col-end-12 mt-[32px] sm:mt-0;
      }
      .first_block_item {
        @apply grid grid-cols-6 sm:grid-cols-5 lg:grid-cols-4 relative;
        img {
          @apply col-start-1 col-end-3 absolute z-0 top-0 left-0 w-[79px] sm:w-[100px] lg:w-[158px] 2xl:w-[228px] h-auto;
        }
        p {
          @apply col-span-full relative z-[1];
          @apply font-apollo text-sm-apollo lg:text-md-apollo 2xl:text-lg-appollo tracking-[0.06em];
        }
      }
    }
  }

  .block_specialite {
    @extend .spacing-block;
    @apply flex flex-col sm:grid sm:grid-cols-12;

    .contents {
      @apply mt-[16px] sm:mt-[40px] lg:mt-[80px] 2xl:mt-[120px] col-span-full xl:col-start-2 xl:col-end-12 sm:grid xl:grid-cols-12 flex flex-col gap-y-8;
      @apply sm:gap-x-[56px] lg:gap-0;

      div:first-child {
        @apply col-span-full sm:col-span-6 col-start-1 lg:col-end-6 xl:col-end-5;
      }

      div:last-child {
        @apply col-span-full sm:col-span-6 lg:col-start-8 xl:col-start-7 sm:col-end-13 xl:col-end-11 mt-[40px] sm:mt-0;
      }
      .specialiteItem {
        &:nth-child(n) {
          @apply col-start-1 col-end-6;
        }

        &:nth-child(2n) {
          @apply col-start-8 col-end-13;
        }
        @apply grid col-span-6 lg:col-span-5 cursor-pointer;
        .image_container {
          @apply overflow-hidden;

          img {
            @apply w-full h-[190px] lg:h-[200px] xl:h-[250px] 2xl:h-[300px] object-cover object-center;
            transform: scale(1);
            transition: transform 0.25s;
          }
        }
        h3 {
          @apply col-span-full mt-[8px] sm:mt-[16px] lg:mt-[24px] 2xl:mt-[32px];
          @extend .h3_local;
        }
        .text {
          @apply col-span-full sm:col-start-1 sm:col-end-6 lg:col-end-4 mt-[8px] sm:mt-[16px] lg:mt-[24px] 2xl:mt-[32px];
          p {
            @extend .paragraph_local;
          }
        }

        .contact_btn {
          @apply col-span-4 mt-[8px] sm:mt-[16px] lg:mt-[24px] 2xl:mt-[32px];
          .btn {
            @apply px-[16px] py-[10px] lg:px-[24px] lg:py-[12px];
          }
        }

        &:hover {
          .image_container {
            img {
              transform: scale(1.1);
              transition: transform 0.25s;
            }
          }
        }
      }
    }
  }

  .block_realisations {
    @extend .spacing-block;
    @apply grid grid-cols-12;
    .contents {
      @extend .contents_local;
      @apply gap-[10px] sm:gap-[12px] lg:gap-[16px] xl:gap-[20px];

      img {
        @apply w-full sm:h-auto object-cover object-center;
        @media (max-width: 640px) {
          height: 134px !important;
        }
      }

      .image_1 {
        @apply col-span-6 sm:col-start-1 sm:col-end-6 xl:col-end-5 w-full h-auto;
        @media (max-width: 640px) {
          @apply row-start-1 row-end-2;
        }
      }

      .image_2_container {
        @apply col-span-6 sm:col-start-6 xl:col-start-5 sm:col-end-11  xl:col-end-9 h-full flex flex-col justify-end;
        @media (max-width: 640px) {
          @apply row-start-1 row-end-2;
        }
      }

      .description {
        @apply col-span-full sm:col-start-1 sm:col-end-5 xl:col-end-4;
        @extend .paragraph_local;
        @apply xl:mt-[-8px];
        .btn_container {
          @apply mt-[16px] lg:mt-[24px] 2xl:mt-[32px];
          button {
            @apply px-[16px] py-[10px] lg:px-[24px] lg:py-[12px];
          }
        }
        @media (max-width: 640px) {
          @apply row-start-3 row-end-4;
        }
      }

      .image_3 {
        @apply col-span-6 sm:col-start-5 sm:col-end-7 xl:col-start-4 xl:col-end-6;
        @media (max-width: 640px) {
          @apply row-start-2 row-end-3;
        }
      }

      .image_4 {
        @apply col-span-6 sm:col-start-7 sm:col-end-12 xl:col-start-6 xl:col-end-11;
        @media (max-width: 640px) {
          @apply row-start-2 row-end-3;
        }
      }
    }
  }

  .block_histoire {
    @extend .spacing-block;
    @apply grid grid-cols-12;

    h2 {
      @extend .h2_local;
    }

    .contents {
      @extend .contents_local;
      @apply md:mt-[32px] 2xl:mt-[139px];
      @apply relative grid grid-cols-12 xl:grid-cols-10;

      .image {
        @apply md:mt-[96px] xl:mt-[160px] 2xl:mt-[200px] relative z-[1] col-span-full sm:col-start-1 sm:col-end-6 md:col-end-6 xl:col-end-5;
      }

      .histoire_text {
        @apply md:absolute top-0 z-[0] col-span-full sm:col-start-7 sm:col-end-13  md:col-span-full xl:col-start-2 xl:col-span-9 md:grid md:grid-cols-12 xl:grid-cols-9;
        @apply mt-[8px] sm:mt-0;

        .bg_white {
          @apply col-start-3 xl:col-start-2 2xl:col-start-1 col-span-8 xl:col-span-6 2xl:col-span-7 row-start-1 row-span-2;
          @apply hidden md:block xl:ml-[40px] 2xl:pl-0;
          @apply bg-background-white;
        }

        .text_container {
          @apply h-auto col-start-7 col-end-13 xl:col-start-5 xl:col-span-5 row-start-1 row-span-2 md:py-[40px] xl:py-[80px] 2xl:py-[120px];

          h3 {
            @extend .h3_local;
          }

          .paragraphs {
            @apply mt-[16px] lg:mt-[24px] 2xl:mt-[32px];

            p {
              @apply first:mt-0 mt-[16px] lg:mt-[24px] xl:mt-[32px];
              @extend .paragraph_local;
            }
          }

          .btn_container {
            @apply mt-[16px] lg:mt-[24px] 2xl:mt-[32px];

            button {
              @apply px-[16px] py-[10px] lg:px-[24px] lg:py-[12px];
            }
          }
        }
      }
    }
  }

  .reviews_container {
    @extend .spacing-block;

    .content_header {
      @apply grid grid-cols-12;

      h2 {
        @apply col-span-full xl:col-start-2 xl:col-end-12;
      }

      p {
        @apply col-span-full xl:col-start-2 xl:col-end-12 mt-[8px];
        @extend .paragraph;
      }

      .moyen_container {
        @apply col-span-full w-full flex justify-center mt-[40px] sm:mt-[56px] lg:mt-[64px] 2xl:mt-[76px] mb-[32px] lg:mb-[72px] 2xl:mb-[120px];

        .moyen {
          @apply flex items-center gap-x-[8px] px-[20px] lg:px-[24px] 2xl:px-[32px] py-[16px] lg:py-[20px] 2xl:py-[24px] border border-brand-2;

          p {
            @apply mt-0 font-poppins font-semibold text-[16px] md:text-[20xp] lg:text-[24px] 2xl:text-[32px];
          }

          img {
            @apply w-[20px] sm:w-[24px] lg:w-[32px] h-auto;
          }
        }
      }
    }
  }
}

.contact_block {
  .contact {
    @apply mt-[16px] md:mt-[32px] lg:mt-[64px];

    .form_container {
      @apply grid grid-cols-10;
    }

    .form {
      @apply col-span-full md:col-start-1 md:col-end-6;
    }

    .image {
      @apply hidden md:block col-start-7 col-end-11;
      img {
        @apply w-full h-[407px] lg:h-[507px] xl:h-[607px] object-cover;
        object-position: 50% 35%;
      }
    }
  }
}
