@import "../../../assets/styles/global.scss";

._block {
  @extend .my-container;
  @apply grid grid-cols-12 lg:mt-[70px] 2xl:mt-[62px];
  .container {
    @apply col-start-1 col-end-13 xl:col-start-2 xl:col-end-12 grid grid-cols-12 xl:grid-cols-10 relative;
    .title_breadCrumbs {
      @apply col-span-full mt-[40px] lg:mt-[36px] relative z-[1];
      .BreadCrumb {
        @apply hidden lg:flex;
      }
      h1 {
        @extend .my-h1;
        @apply mt-[4px];
      }
    }

    .hero_image_container {
      @apply col-span-full md:col-start-2 md:col-end-6 xl:col-end-5 mt-[16px] md:mt-[56px] lg:mt-[80px] 2xl:mt-[117px] relative z-[1];
      img {
        @apply w-full h-auto;
      }
    }

    .block_white {
      @apply col-span-full relative md:absolute z-0 md:top-[16px] lg:top-0 md:col-start-5 xl:col-start-4 md:col-end-13 xl:col-end-11 md:bg-background-white w-full md:grid grid-cols-8 xl:grid-cols-7;
      @apply md:pt-[96px] md:pb-[64px] lg:pt-[144px] 2xl:pt-[196px] lg:pb-[96px] 2xl:pb-[126px];
      .contents {
        @apply col-span-full md:col-start-3 md:col-end-8 xl:col-end-7;

        .hero_text_container {
          p {
            @media (min-width: 786px) {
              @apply font-apollo text-sm-apollo lg:text-md-apollo 2xl:text-lg-appollo tracking-[0.06em];
            }

            @extend .paragraph;
            @apply first:mt-0 mt-[16px];
          }
        }

        .btn_container {
          @apply mt-[16px] md:mt-[24px] xl:mt-[32px] col-span-full;
        }
      }
    }
  }
}
