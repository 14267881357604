@import "../../../assets/styles/global.scss";

.container_review {
  @apply grid grid-cols-12;

  .swiper_container {
    @apply col-span-full xl:col-start-2 xl:col-end-12 relative;

    .prev {
      @apply absolute left-[-2%] top-[45%] min-w-[30px] cursor-pointer;
      transform: translate(-50%, 0);
    }

    .next {
      @apply absolute top-[45%] min-w-[30px] cursor-pointer;
      transform: translate(-50%, 0);
      left: 102%;
    }
  }

  .slide_container {
    @apply bg-background-white w-full;
  }
}

.bullet_wrapper {
  --var-w-bullet: 40px;
  --var-gap-x: 24px;

  @media (min-width: 768px) {
    --var-w-bullet: 64px;
    --var-gap-x: 24px;
  }

  width: calc((var(--var-gap-x) + var(--var-w-bullet)) * 3);

  @apply overflow-hidden;

  .bullet_container {
    @apply flex flex-nowrap;

    column-gap: var(--var-gap-x);

    transition: margin-left 0.25s;

    .bullet {
      @apply h-[6px] cursor-pointer border border-flush-orange-500 hover:bg-flush-orange-500;
      min-width: var(--var-w-bullet);

      // &:first-child {
      //   margin-left: var(--var-gap-x);
      // }
    }
  }
}
