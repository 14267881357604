@import "../../../assets/styles/global.scss";

.contactez_nous {
  @apply bg-background sm:px-[60px] pb-[82px] pt-[210px];
  // @extend .my-container;
  // @extend .spacing-block ;
  // @apply bg-background-white sm:bg-transparent;


  .block {
    @apply bg-background-white grid grid-cols-12 px-6 py-[40px] sm:py-[64px] lg:py-[120px];
    .contents {
      @apply col-span-full sm:col-start-3 sm:col-end-11 2xl:col-start-3 2xl:col-end-11 flex flex-col items-center;
      h2 {
        @apply font-apollo text-base-apollo sm:text-2xl lg:text-[56px] lg:leading-[65.46px] 2xl:text-[70px] 2xl:leading-[93.52px] tracking-[0.06em] text-flush-orange-500 text-center;
      }

      p {
        @apply font-apollo text-sm-apollo 2xl:text-lg tracking-[0.06em] mt-[40px] sm:mt-[24px] lg:mt-[32px] text-center;
      }

      .btn_contactez_nous {
        @apply flex justify-between mt-[40px] sm:mt-[24px] lg:mt-[32px];
        button {
          @apply px-[16px] py-[10px] lg:px-[24px] lg:py-[12px];
        }
      }
    }
  }
}
